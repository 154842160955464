body {


  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
table, th, td {
  text-align:center;
  border: 1px none ;
  background-color: bisque;
  font-family:"Tajawal";
}
table{
  border: 1px dotted;
}
#hjemmelag {
  color: skyblue
}
#bortelag{
  color: lightcoral
}
#mål1{
  color: skyblue
}
#mal2{
  color: lightcoral
}
#skuddsjanser{
  color:black ;
}